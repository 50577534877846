import axios, { AxiosResponse, CancelTokenSource } from "axios";
import api from "./axiosInstance";
import {MergeableAccount} from "./loginApi";

export interface UserDetails {
  id: string;
  name: string;
  email?: string;
  appleId?: string;
  spotifyId?: string;
  accountCreationDate: number;
  profileImage?: string;
  spotifyProfileImage?: string;
  playlistLocalSongsPerSeed?: number;
  anonymousUser: boolean;
  emailConnected: boolean;
  appleConnected: boolean;
  spotifyConnected: boolean;
  emailVerified: boolean;
  emailOptIn: boolean;
  isAdmin: boolean;
  isTeamMember: boolean;
  playlistUseSeedSongs: boolean;
}


export interface ErrorResponse {
  status: number;
  error: string;
  message: string;
  path: string;
  timestamp: string;
  requestId: string;
}

export interface CityTopArtist {
  id: string;
  name: string;
  image: string | undefined;
  popularity: number;
}

export interface CityResponse {
  id: string;
  name: string;
  image?: string | undefined;
  zoneCode: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  numberOfArtists: number;
  numberTotalVenues: number;
  numberUpcomingEvents: number;
  spotifyPlaylistId: string | undefined;
  applePlaylistId: string | undefined;
  topArtists: PageableCityArtistResponse;
  isUserLocalCity: boolean;
  isFestival: boolean;
}

export interface UserNearestCities {
  cities: CityResponse[];
  fallbackResponse: boolean;
}

interface UserCityPutRequest {
  radius: number;
}

export enum CitySelectionTimeFrame {
  CUSTOM = 'CUSTOM',
  DAYS_7 = 'DAYS_7',
  DAYS_30 = 'DAYS_30',
  DAYS_90 = 'DAYS_90',
  ALL = 'ALL'
}

export enum CitySortBy {
  SCORE = "SCORE",
  DATE = "DATE"
}

export interface UserCity {
  id: string;
  name: string;
  zoneCode: string;
  timeFrame: CitySelectionTimeFrame;
  startDate: number | undefined;
  endDate: number | undefined;
  radius: number;
  selectedAt: string;
  sortBy: CitySortBy;
  spotifyPlaylist: string;
  countryCode: string;
  isFestival: boolean;
}

export interface ArtistCity {
  id: string;
  name: string;
  zoneCode: string;
  countryCode: string;
  friendlyName?: string;
  latitude: number;
  longitude: number;
  applePlaylistId?: string | undefined;
  spotifyPlaylistid?: string | undefined;
}

export interface GenreResponse {
  id: string;
  name: string;
}

export interface SimilarArtist {
  id: string;
  name: string;
  score: number;
  image: string | undefined;
}

export interface BasicArtistResponse {
  id: string;
  name: string;
  image: string | undefined;
}

export interface ArtistResponse {
  id: string;
  name: string;
  image: string | undefined;
  appleId: string | undefined;
  spotifyId: string | undefined;
  city?: ArtistCity | undefined;
}

//Called for fetchArtist()
export interface ComplexArtistResponse {
  id: string;
  name: string;
  image: string | undefined;
  appleId: string | undefined;
  spotifyId: string | undefined;
  genres: GenreResponse[];
  similarArtists: BasicArtistResponse[];
  isFavorite: boolean;
  topSongPreview: string | undefined;
  isSeed: boolean | undefined;
}

export interface ArtistDetailResponse {
  id: string;
  name: string;
  image: string;
  appleId: string;
  spotifyId: string;
  genres: GenreResponse[];

  similarArtists: ArtistResponse[]
  isFavorite: boolean

  topSong: {
    songId: string;
    songName: string;
    previewUrl: string;
  }
}

enum CityRelation {
  ORIGINATED = 'ORIGINATED',
  DIED = 'DIED',
  LIVES = 'LIVES'
}

//Specific to for fetchArtistsForCities()
export interface CityArtistResponse {
  id: string;
  name: string;
  image: string;
  relation: CityRelation;
}

export interface BasicArtistResponseWithIds {
  id: string;
  name: string;
  image: string | undefined;
  appleId: string | undefined;
  spotifyId: string | undefined;
  spotifyTopTrackPreview?: string | undefined;
}

//Specific to recommendation responses (as opposed to general artist info)
export interface ArtistRecResponse {
  id: string;
  name: string;
  image: string | undefined;
  score: number;
  genres: GenreResponse[];
  isSeed: boolean | undefined; //Being removed from the backend???
  isFavorite: boolean;
  similar: SimilarArtist[];
  spotifyTopTrackPreview?: string | undefined;
}

export interface ArtistEventsResponse {
  city: CityResponse;
  otherEvents: EventResponse[];
  nearbyEvents: EventResponse[];
}

export interface EventRecResponse {
  id: string;
  name: string;
  percentMatch: number;
  startTime: number;
  ticketUrl?: string;
  venue: VenueResponse;
  artists: BasicArtistResponseWithIds[];
  hasLocalArtistPerforming: boolean;
  similar: BasicArtistResponse[];
  genres: GenreResponse[];
  isFavorite: boolean;
}

export interface VenueResponse {
  id: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  city: CityResponse;
}

export interface EventResponse {
  id: string;
  name: string;
  startTime: number;
  endTime: number | undefined;
  lowPrice: number | undefined;
  highPrice: number | undefined;
  ticketUrl: string | undefined;
  isFavorite: boolean;
  applePlaylistId : string | undefined;
  spotifyPlaylistId: string | undefined;
  venue: VenueResponse;
  topArtists: ArtistResponse[];
}

export interface FavoriteEventResponse {
  id: string;
  name: string;
  startTime: number;
  endTime: number | undefined;
  lowPrice: number | undefined;
  highPrice: number | undefined;
  ticketUrl: string | undefined;
  isFavorite: boolean;
  applePlaylistId : string | undefined;
  spotifyPlaylistId: string | undefined;
  venue: VenueResponse;
  topArtists: ArtistResponse[];
  rating: Rating;
}

export interface SearchResponse {
  artists: ArtistResponse[]

  events: EventResponse[]

  venues: VenueResponse[]

  cities: CityResponse[]

}

export interface SeedResponse {
  id: string;
  name: string;
  image: string | undefined;
  appleId: string;
  spotifyId: string
  isBlacklisted: boolean
}

export interface PageableResponse {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: false;
      unsorted: boolean;
    }
    unpaged: boolean;
  }
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  }
  totalElements: number;
  totalPages: number;
}

export interface PageableCityArtistResponse extends PageableResponse {
  content: CityArtistResponse[];
}

export interface PageableVenueResponse extends PageableResponse {
  content: VenueResponse[];
}

export interface PageableEventResponse extends PageableResponse {
  content: EventResponse[];
}

export interface PageableFavoriteEventResponse extends PageableResponse {
  content: FavoriteEventResponse[];
}

export interface PageableArtistResponse extends PageableResponse {
  content: ArtistResponse[];
}

export interface UserCityResponse {
  current: UserCity;
  others: UserCity[];
}

export interface UserCityPatchRequestBody {
  customStartDate?: string;
  customEndDate?: string;
  timeFrame?: CitySelectionTimeFrame;
  radius?: number;
  selected?: boolean;
}

export interface EmailVerificationResponse {
  nonce: string;
}

export interface TopGenresResponse {
  id: string;
  name: string;
  topArtists: ArtistResponse[];
}

export interface PatchUserDetailsResponse {
  name: string;
  emailOptIn: boolean
}

export interface PlaylistResponse {
  id: string;
  name: string;
  description: string;
  spotifyId: string;
}

export enum FavoriteTypes {
  artists = "artists",
  events = "events",
  venues = "venues"
}

export enum Rating {
  BAD = "BAD",
  GOOD = "GOOD",
  GREAT = "GREAT",
  DID_NOT_GO = "DID_NOT_GO"
}

export enum FavoriteTimeFrame {
  PREVIOUS = "previous",
  UPCOMING = "upcoming"
}



/* ----- User Endpoints (@me) ----- */
export const fetchUserDetails = async (): Promise<UserDetails> => {
  try {
    const response: AxiosResponse<UserDetails> = await api.get(`/v1/@me`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching UserDetails');
  }
};

export const patchUserDetails = async (userDetails: PatchUserDetailsResponse): Promise<UserDetails> => {
  try {
    const response: AxiosResponse<UserDetails> = await api.patch(`/v1/@me`, userDetails);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching UserDetails');
  }
};

export const deleteUserAccount = async () => {
  try{
    const response : AxiosResponse = await api.delete(`v1/@me`)
  }catch (error){
    throw new Error('Error deleting account ' + error)
  }
}

//User Cities
export const fetchUserNearestCities = async (): Promise<UserNearestCities> => {
  try {
    const response: AxiosResponse<UserNearestCities> = await api.get(`/v1/@me/cities/nearest`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching Nearest Cities');
  }
};

export const fetchUserCities = async (): Promise<UserCityResponse> =>{
  try {
    const response: AxiosResponse<UserCityResponse> = await api.get(`v1/@me/cities`)
    return response.data
  } catch(error){
    throw new Error('Error fetching cities');
  }
}

export const putUserCity = async (cityId: string, radius: number, onboarding?: boolean): Promise<UserCity> => {
  try {
    const response: AxiosResponse<UserCity> = await api.put(`/v1/@me/cities/${cityId}${onboarding ? "/onboarding" : ""}`, {
      radius: radius
    });
    return response.data;
  } catch (error) {
    throw new Error('Error putting user city: ' + error);
  }
}

export const fetchUserCityPlaylist = async (cityId: string): Promise<PlaylistResponse> => {
  try {
    const response: AxiosResponse<PlaylistResponse> = await api.post(`/v1/@me/cities/${cityId}/playlist-async`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching playlist: ' + error);
  }
}

//Select a new current city
export const patchUserCities = async (cityId: string, selected: boolean): Promise<UserCity> =>{
  try{
    const response: AxiosResponse<UserCity> = await api.patch(`v1/@me/cities/${cityId}`, {selected: selected})
    return response.data
  }catch(error){
    throw new Error('Error patching city ' + error)
  }
}

export const deleteUserCity = async (cityId: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await api.delete(`v1/@me/cities/${cityId}`)
    return response.data;
  } catch (error) {
    throw new Error('Error deleting user city' + error);
  }
}

//User Seeds
export const fetchUserSeeds = async () : Promise<SeedResponse[]> => {
  try{
    const response: AxiosResponse<SeedResponse[]> = await api.get(`v1/@me/seeds`);
    return response.data;
  } catch(error){
    throw new Error('Error fetching seeds ' + error);
  }
}

export const fetchAllUserSeeds = async () : Promise<SeedResponse[]> => { // Fetches including blacklisted seeds
  try{
    const response: AxiosResponse<SeedResponse[]> = await api.get(`v1/@me/seeds/all`);
    return response.data;
  } catch(error){
    throw new Error('Error fetching seeds ' + error);
  }
}

export const putUserSeeds = async (seeds: string[]): Promise<SeedResponse> =>{
  try {
    const response: AxiosResponse<SeedResponse> = await api.put(`v1/@me/seeds`, {
      seeds: seeds
    });
    return response.data;
  } catch(error){
    throw new Error('Error sending seeds to system.');
  }
}

export const addArtistToUserSeeds = async (seedId: string): Promise<void> => {
  try {
    const response: AxiosResponse<void> = await api.put(`v1/@me/seeds/${seedId}`);
    return response.data;
  } catch {
    throw new Error('Error adding artist to list of seeds');
  }
}

export const blacklistSeedArtist = async (seedId: string): Promise<void> => {
  try {
    const response: AxiosResponse<void> = await api.delete(`v1/@me/seeds/${seedId}`);
    return response.data;
  } catch {
    throw new Error('Error blacklisting artist to list of seeds');
  }
}

export const unBlacklistSeedArtist = async (seedId: string): Promise<void> => {
  try {
    const response: AxiosResponse<void> = await api.post(`v1/@me/seeds/${seedId}`);
    return response.data;
  } catch {
    throw new Error('Error unblacklisting to list of seeds');
  }
}

export const connectExistingUserSpotify = async (queryParams: String): Promise<string> => {
  try {
    const response: AxiosResponse<String> = await api.get(`/v1/@me/spotify/link` + queryParams);
    return response.data as string
  } catch (error) {
    throw new Error('Error connecting to Spotify');
  }
};

export const getMergeableAccount = async (authToken: string): Promise<MergeableAccount> => {
  try {
    const response: AxiosResponse<MergeableAccount> = await api.get('/v1/@me/mergeable-account?mergeToken=' + authToken);
    return response.data;
  } catch (error) {
    throw new Error('Invalid Mergeable Account');
  }
}

//User Favorites
export const addFavorite = async (id: string, type: FavoriteTypes)=>{
  try{
    const response : AxiosResponse = await api.put(`v1/@me/${type}/${id}/favorite`)
  }catch(error){
    throw new Error('Error putting favorite ' + error)
  }
}

export const removeFavorite = async (id: string, type: FavoriteTypes)=>{
  try{
    const response : AxiosResponse = await api.delete(`v1/@me/${type}/${id}/favorite`)
  }catch(error){
    throw new Error('Error putting favorite ' + error)
  }
}

export const rateEvent = async (eventId: string, rating: Rating) => {
  try {
    const response : AxiosResponse = await api.put(`v1/@me/events/${eventId}/rate?rating=${rating}`);
  } catch (error) {
    throw new Error('Error rating event ' + error);
  }
}

export const fetchFavorites = async (type: FavoriteTypes, json?: {page?: number, limit?: number}, timeFrame?: FavoriteTimeFrame):
    Promise<PageableArtistResponse | PageableVenueResponse | PageableFavoriteEventResponse> => {
  let queryString = "";
  if (json) {
    queryString = pageAndLimitString(json);
  }

  let timeFrameAppend = "";
  if (timeFrame) {
    timeFrameAppend = "/" + timeFrame;
  }

  try {
    const response: AxiosResponse<PageableArtistResponse> = await api.get(`v1/@me/${type}/favorites${timeFrameAppend}${queryString}`);
    return response.data;
  } catch {
    throw new Error(`Error fetching ${FavoriteTypes} favorites`)
  }
}

//User Search History
export const fetchUserSearchHistory = async (): Promise<string[]> => {
  try {
    const response: AxiosResponse<string[]> = await api.get(`v1/@me/search-history`);
    return response.data;
  } catch {
    throw new Error('Error fetching search history');
  }
}

export const deleteUserSearchHistory = async (): Promise<void> => {
  try {
    const response: AxiosResponse<void> = await api.delete(`v1/@me/search-history`);
    return response.data;
  } catch {
    throw new Error('Error fetching search history');
  }
}


//Email Verification
export const emailVerification = async (email: string): Promise<EmailVerificationResponse> => {
  try {
    const json = {
      email: email
    }
    const response: AxiosResponse<EmailVerificationResponse> = await api.post(`/v1/auth/email/send-token`, json);
    return response.data;
  } catch (error){
    throw new Error('Error setting up email verification')
  }
}




/* ----- Artist Endpoints ----- */
export const fetchArtist = async (artistId: string): Promise<ComplexArtistResponse> =>{
  try{
    const response: AxiosResponse<ComplexArtistResponse> = await api.get(`v1/artists/${artistId}`)
    return response.data
  }catch(error){
    throw new Error('Error fetching artist ' + error)
  }
}

//Artist Events
export const fetchEventsForArtist = async (artistId: string) :Promise<ArtistEventsResponse>=>{
  try {
    const response: AxiosResponse<ArtistEventsResponse> = await api.get(`v1/artists/${artistId}/events`);
    return response.data;
  } catch(error) {
    throw new Error('Error fetching events for artist ' + error)
  }
}

//Artist Cities
export const fetchCitiesForArtist = async (artistId: string): Promise<CityResponse[]> =>{
  try{
    const response: AxiosResponse<CityResponse[]> = await api.get(`v1/artists/${artistId}/cities`)
    return response.data
  }catch(error){
    throw new Error('Error fetching cities for artist ' + error)
  }
}

export const addCityForArtist = async (artistId: string, cityId: string): Promise<CityResponse> => {
  const body = {
    cityId: cityId
  }

  try {
    const response: AxiosResponse<CityResponse> = await api.post(`v1/artists/${artistId}/cities`, body)
    return response.data
  }catch(error){
    throw new Error('Error fetching cities for artist ' + error)
  }
}

//Search Artists
let artistSearchSource: CancelTokenSource;
export const fetchSearchArtists = async (text: string, limit?: number): Promise<ArtistResponse[]> =>{
  //If there's already an outgoing request, cancel it before it gets back
  if (artistSearchSource) {
    artistSearchSource.cancel()
  }

  if (text == "")
    return []

  artistSearchSource = axios.CancelToken.source();
  let config = { cancelToken: artistSearchSource.token }
  try{
    const response: AxiosResponse<ArtistResponse[]> = await api.get(
        `v1/artists/search?q=${text}&limit=${limit ? limit : 12}`, config);
    return response.data;
  }catch(error){
    throw new Error(`Error fetching artists for search\nletter searched: ${text}`)
  }
}



/* ----- City Endpoints ----- */
export const fetchCityDetails = async (cityId: string): Promise<CityResponse> => {
  try{
    const response: AxiosResponse<CityResponse> = await api.get(`v1/cities/${cityId}`)
    return response.data
  }catch(error){
    throw new Error('Error fetching city ' + error)
  }
}

//Artists local to a City
export const fetchArtistsForCities = async (cityId: string, json?: {page?: number, limit?: number}) : Promise<PageableCityArtistResponse> => {
  let queryString = "";
  if (json) {
    queryString = pageAndLimitString(json);
  }

  try{
    const response: AxiosResponse<PageableCityArtistResponse> = await api.get(`v1/cities/${cityId}/artists${queryString}`)
    return response.data
  } catch(error){
    throw new Error('Error fetching city artists '  + error)
  }
}

//Events for a City
export const fetchEventsForCities = async (cityId: string, json?: {page?: number, limit?: number}): Promise<PageableEventResponse> => {
  let queryString = "";
  if (json) {
    queryString = pageAndLimitString(json);
  }

  try {
    const response: AxiosResponse<PageableEventResponse> = await api.get(`v1/cities/${cityId}/events${queryString}`);
    return response.data;
  } catch(error) {
    throw new Error('Error fetching city events')
  }
}

//Venues for a City
export const fetchVenuesForCities = async (cityId: string, json?: {page?: number, limit?: number}): Promise<PageableVenueResponse> => {
  let queryString = "";
  if (json) {
    queryString = pageAndLimitString(json);
  }

  try {
    const response: AxiosResponse<PageableVenueResponse> = await api.get(`v1/cities/${cityId}/venues${queryString}`);
    return response.data;
  } catch(error) {
    throw new Error('Error fetching city venues');
  }
}

//Search Cities
let searchCitiesSource: CancelTokenSource;
export const fetchSearchCities = async (text: string) : Promise<CityResponse[]> =>{
  //If there's already an outgoing request, cancel it before it gets back
  if (searchCitiesSource) {
    searchCitiesSource.cancel()
  }

  if (text == "")
    return []

  searchCitiesSource = axios.CancelToken.source();
  let config = { cancelToken: searchCitiesSource.token }
  try{
    const response : AxiosResponse<CityResponse[]> = await api.get(
        `v1/cities/search?q=${text}&limit=10`, config);
    return response.data
  }catch(error){
    throw new Error(`Error fetching events for search\nletter searched: ${text}`)
  }
}


/* ----- Genre Endpoints ----- */
export const fetchCuratedGenres = async (): Promise<GenreResponse[]> => {
  try {
    const response: AxiosResponse<GenreResponse[]> = await api.get(`/v1/genres/curated`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching curated genres: ' + error);
  }
}

export const fetchTopGenres = async (): Promise<TopGenresResponse[]> => {
  try {
    const response: AxiosResponse<TopGenresResponse[]> = await api.get(`/v1/genres/top`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching genres and artists');
  };
};

export const fetchArtistsForGenres = async (genres: string[]): Promise<ArtistResponse[]> => {
  const genresList = genres.join(',');
  try {
    const response: AxiosResponse<ArtistResponse[]> = await api.get(`/v1/artists/popular?genres=${genresList}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching artists for genres: ' + error);
  }
}



/* ----- Event Endpoints ----- */
export const fetchEvent = async (eventId: string): Promise<EventResponse> => {
  try {
    const response: AxiosResponse<EventResponse> = await api.get(`/v1/events/${eventId}`)
    return response.data;
  } catch (error) {
    throw new Error('Error fetching event: ' + error);
  }
}



/* ----- Recommendation Endpoints ----- */
export const fetchArtistRecommendations = async (cityId: string): Promise<ArtistRecResponse[]> => {
  try {
    const response: AxiosResponse<ArtistRecResponse[]> = await api.get(`v1/@me/cities/${cityId}/artist-recommendations`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status) {
      if (error.response.status === 409) {
        throw new Error("No user seeds");
      } else if (error.response.status === 400) {
        throw new Error("Radius too small");
      } else {
        throw new Error("Failed to fetch artist recs");
      }
    } else {
      throw new Error("Failed to fetch artist recs");
    }
  }
}

export const fetchEventRecommendations = async (cityId: string, json: {sortBy?: CitySortBy, timeFrame?: CitySelectionTimeFrame, start?: number, end?: number}): Promise<EventRecResponse[]> => {
  try {
    const response: AxiosResponse<EventRecResponse[]> = await api.post(`v1/@me/cities/${cityId}/event-recommendations`, json);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status) {
      if (error.response.status === 409) {
        throw new Error("No user seeds");
      } else if (error.response.status === 400) {
        throw new Error("Radius too small");
      } else {
        throw new Error("Failed to fetch event recs");
      }
    } else {
      throw new Error("Failed to fetch event recs");
    }
  }
}



/* ----- Venue Endpoints ----- */

export const fetchVenue = async (id: string): Promise<VenueResponse> => {
  try {
    const response: AxiosResponse<VenueResponse> = await api.get(`v1/venues/${id}`);
    return response.data;
  } catch {
    throw new Error("Failed to fetch venue data");
  }
}

export const fetchVenueUpcomingEvents = async (id: string): Promise<EventResponse[]> => {
  try {
    const response: AxiosResponse<EventResponse[]> = await api.get(`v1/venues/${id}/upcoming-events`);
    return response.data;
  } catch {
    throw new Error("Failed to fetch venue upcoming event data");
  }
}



/* ----- Miscellaneous Endpoints ----- */

let generalSearchSource: CancelTokenSource
export const fetchSearch = async (text: string, autoSearchSpotify: boolean) : Promise<SearchResponse> =>{
  if (generalSearchSource)
    generalSearchSource.cancel();

  if (text === "") {
    return {artists: [], cities: [], events: [], venues: []};
  }

  generalSearchSource = axios.CancelToken.source();
  let config = { cancelToken: generalSearchSource.token }
  try {
    const response: AxiosResponse<SearchResponse> = await api.get(`v1/search?q=${text}&autoSearchSpotify=${autoSearchSpotify}`, config)
    return response.data
  } catch(error){
    throw error;
  }
}

export const submitFeedback = async (entry: string, email: string)=>{
  try{
    const response : AxiosResponse = await api.put(`v1/@me/feedback`, {
      entry: entry,
      email: email
    })
  } catch(error){
    throw new Error('Error putting feedback ' + error)
  }
}

/* ----- Helper functions ----- */
const pageAndLimitString = (json: {page?: number, limit?: number}): string => {
  if (json.page && json.limit)
    return `?page=${json.page}&limit=${json.limit}`;
  else if (json.page)
    return `?page=${json.page}`;
  else if (json.limit)
    return `?limit=${json.limit}`;
  else
    return "";
}
