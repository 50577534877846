import React, {useEffect} from 'react';
import './style/App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import SpotifyRedirect from "./pages/SpotifyRedirect";
import ChooseLocation from "./pages/ChooseLocation";
import NewChooseArtists from "./pages/ChooseArtists"
import NotFound from "./pages/NotFound";
import Recommendations from "./pages/Recommendations";
import ArtistDetail from "./pages/ArtistDetail";
import Settings from "./pages/Settings";
import AboutUs from "./pages/AboutUs";
import BackendTesting from "./pages/BackendTesting";
import EventDetail from "./pages/EventDetail";
import CityDetail from "./pages/CityDetail";
import Search from "./pages/Search";
import VenueDetail from "./pages/VenueDetail";
import Favorites from "./pages/Favorites";
import Home from "./pages/Home";
import EmailRedirect from "./pages/EmailRedirect";
import SpotifyLinkRedirect from "./pages/SpotifyLinkRedirect";
import FeedbackForm from "./pages/FeedbackForm";
import {initGA, logPageView} from "./analytics";
import SavedEntities from "./pages/SavedEntities";
import {UserProvider} from "./hooks/UserContext";
import {RecommendationsProvider} from "./hooks/RecommendationsContext";
import OnboardCityRedirect from "./pages/OnboardCityRedirect";

// Top of the tree of the entire application. Contains the appropriate route for each page.
function App() {

    useEffect(() => {
        initGA("G-8CWTWSDZTL");
        logPageView(); // Log initial page load
    }, []);

    return (
        <UserProvider>
            <RecommendationsProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" Component={Home}/>
                        <Route path="/team/about-us" Component={AboutUs}/>

                        <Route path="/login" Component={Login}/>

                        {/* :process is an enum. 'onboarding', 'add', or 'edit-radius' */}
                        <Route path="/:procedure/location" Component={ChooseLocation}/>
                        <Route path="/onboarding/artists" Component={NewChooseArtists}/>
                        <Route path="/spotify-login" Component={SpotifyRedirect}/>
                        <Route path="/spotify-link" Component={SpotifyLinkRedirect}/>
                        <Route path="/email-link" Component={EmailRedirect}/>
                        <Route path="/recommendations" Component={Recommendations}/>
                        <Route path="/artist/:id/:slug" Component={ArtistDetail}/>
                        <Route path="/event/:id/:slug" Component={EventDetail}/>
                        <Route path="/city/:id/:slug" Component={CityDetail}/>
                        <Route path="/venue/:id/:slug" Component={VenueDetail}/>
                        <Route path="/:type/favorites" Component={Favorites}/>
                        <Route path="/search" Component={Search}/>
                        <Route path="user/settings" Component={Settings}/>
                        <Route path="/feedback-form" Component={FeedbackForm}/>
                        <Route path="/saved/:type" Component={SavedEntities}/>
                        <Route path="/onboard-city" Component={OnboardCityRedirect}/>
                        <Route path={"*"} element={<NotFound/>}/>

                        <Route path={"/backend-testing"} element={<BackendTesting/>}/>
                    </Routes>
                </BrowserRouter>
            </RecommendationsProvider>
        </UserProvider>
    );
}

export default App;
