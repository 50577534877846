import secureLocalStorage from "react-secure-storage";
import { AuthCredentials, LoginStrategy } from "./loginApi";

export const storeOnboardCity = async (cityId: string) => {
  try {
    secureLocalStorage.setItem("onboardCity", cityId);
    secureLocalStorage.setItem("onboardCityTime", new Date().getTime());
  } catch (error) {
    console.log('Error storing pre-onboard city:', error);
  }
}

export const getOnboardCity = () => {
  try {
    const cityId = secureLocalStorage.getItem('onboardCity');
    const cityTime = secureLocalStorage.getItem('onboardCityTime');
    if (cityId && cityTime)
      return {onboardCity: cityId, onboardCityTime: cityTime}
    else
      throw new Error("Pre-onboard city undefined")
  } catch (error) {
    console.log('Error retrieving pre-onboard city:', error);
  }
}

export const storeTokenData = async (authToken: AuthCredentials) => {
  try {
    //console.log(JSON.stringify(authToken));
    secureLocalStorage.setItem('tokenData', JSON.stringify(authToken));
  } catch (error) {
    console.log('Error storing token data:', error);
  }
};

export const getTokenData = () => {
  try {
    const authCredentials = secureLocalStorage.getItem('tokenData');
    if (authCredentials)
      return JSON.parse(<string>authCredentials);
    else
      throw new Error("Token data undefined")
  } catch (error) {
    console.log('Error retrieving token:', error);
  }
};

export const clearAuthToken = () => {
  try {
    secureLocalStorage.removeItem('tokenData');
  } catch (error) {
  }
};

export const storeSpotifySecret = async (secret: string) => {
  try {
    await secureLocalStorage.setItem('SpotifySecret', secret);
  }
  catch {
    throw new Error('Error storing Spotify secret');
  }
}

export const getSpotifySecret = async () => {
  try {
    let secret = await secureLocalStorage.getItem('SpotifySecret');
    if (secret)
      return secret as string;
    else
      throw new Error('secret undefined');
  }
  catch {
    throw new Error('Error retrieving Spotify secret');
  }
}

export const storeUserLoginStrategy = (loginStrategy: LoginStrategy) => {
  try {
    secureLocalStorage.setItem("LoginStrategy", loginStrategy);
  } catch {
    throw new Error("Failed to set Login Strategy")
  }
}

export const getUserLoginStrategy = () => {
  return secureLocalStorage.getItem("LoginStrategy");
}
