import * as Store from "../backend/storage";
import * as apiService from "../backend/apiService"
import secureLocalStorage from "react-secure-storage";

const handleLogin = async () => {
    try {
        const tokenData = Store.getTokenData();
        if (!tokenData)
            return "/login";

        const preOnboardCityData = Store.getOnboardCity();
        if (preOnboardCityData) {
            secureLocalStorage.removeItem("onboardCity");
            secureLocalStorage.removeItem("onboardCityTime");
            await apiService.putUserCity(preOnboardCityData.onboardCity as string, 5);
        }

        const cities = await apiService.fetchUserCities();
        console.log(cities);
        if (cities.current) {
            try {
                await apiService.fetchArtistRecommendations(cities.current.id);
                return "/recommendations";
            } catch (error) {
                return "/onboarding/artists";
            }
        } else {
            return "/onboarding/location";
        }
    } catch (error) {
        return await apiService.fetchUserSeeds().then(res => {
            if (res.length > 0) return "/onboarding/location?loginType=artists";
            else return "/onboarding/location";

        }).catch(() => {
            return "/onboarding/location";
        })
    }
};


export default handleLogin;
