import React, {useState} from "react";
import * as apiService from "../backend/apiService";
import {Rating} from "../backend/apiService";
import styles from "../style/components/RateEventButton.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import {
    CancelOutlined,
    CloseOutlined,
    Star,
    StarBorder,
    ThumbDownAlt, ThumbDownAltOutlined,
    ThumbUpAlt,
    ThumbUpAltOutlined
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

type Props = {
    type: Rating;
    eventId: string;
    handleError: () => void;
    currentType: Rating;
    setRating: (eventId: string, rating: Rating) => void;
}

const RateEventButton: React.FC<Props> = ({type, eventId, handleError, currentType, setRating}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<boolean>(type === currentType);

    const handleRateClicked = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!loading) {
            setLoading(true);
            try {
                await apiService.rateEvent(eventId, type);
                setRating(eventId, type);
            } catch {
                handleError();
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <Tooltip
            title={
                type === Rating.GREAT ? "A truly great event!" :
                    type === Rating.GOOD ? "A good event" :
                        type === Rating.BAD ? "Not my jam" :
                            "I didn't go"}
            placement={"top"}
            arrow
        >
            <div
                className={
                type === Rating.GREAT ? `${styles["rate-button-container"]} ${styles["great"]}` :
                    type === Rating.GOOD ? `${styles["rate-button-container"]} ${styles["good"]}` :
                        type === Rating.BAD ? `${styles["rate-button-container"]} ${styles["bad"]}` :
                            `${styles["rate-button-container"]} ${styles["did-not-go"]}`}
                onClick={handleRateClicked}
            >
                {loading ? (
                    <CircularProgress className={'primary'} />
                ) : (
                    type === Rating.GREAT ? (
                        type === currentType ? (
                            <Star className={styles['selected']} fontSize={"small"}/>
                        ) : (
                            <StarBorder className={styles['selected']} fontSize={"small"} />
                        )
                    ) : (
                        type === Rating.GOOD ? (
                            type === currentType ? (
                                <ThumbUpAlt className={styles['selected']} fontSize={"small"}/>
                            ) : (
                                <ThumbUpAltOutlined className={styles['selected']} fontSize={"small"} />
                            )
                        ) : (
                            type === Rating.BAD ? (
                                type === currentType ? (
                                    <ThumbDownAlt className={styles['selected']} fontSize={"small"}/>
                                ) : (
                                    <ThumbDownAltOutlined className={styles['selected']} fontSize={"small"} />
                                )
                            ) : (
                                type === currentType ? (
                                    <CancelOutlined className={styles['selected']} fontSize={"small"}/>
                                ) : (
                                    <CloseOutlined className={styles['selected']} fontSize={"small"} />
                                )
                            )
                        )
                    )
                )}
            </div>
        </Tooltip>
    )
};

export default RateEventButton;
