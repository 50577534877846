import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import "../style/App.scss"
import ActivityIndicator from "../components/ActivityIndicator";
import * as Store from "../backend/storage";

const OnboardCityRedirect = () => {
    let [searchParams] = useSearchParams();

    const navigate = useNavigate();
    useEffect(() => {
        const cityId = searchParams.get("cityId");
        if (cityId != null) {
            Store.storeOnboardCity(cityId).then();
        }
        navigate("/login")
    }, [])

    return (
        <div className={"center-loading-button"}>
            <center><ActivityIndicator/><p>Loading...</p></center>
        </div>
    )
}

export default OnboardCityRedirect
